import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2024 from '../CurricularClass52023/components/Sidebar2024';
import Archive_2022 from '../CurricularClass52023/Archive_2022';


const Class6Fieldtrip2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class6Fieldtrip/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class6Fieldtrip/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class6Fieldtrip/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class6Fieldtrip/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class6Fieldtrip/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class6Fieldtrip/6.webp`;
    const p7 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class6Fieldtrip/7.webp`;
    const p8 = `${config.base_image_url}/home/events-activities/co-curricular-activities/Class6Fieldtrip/8.webp`;

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 2,
            cols: 2,
        },
        {
            src: p2,
            source: p2,
            rows: 2,
            cols: 2,
        },
        {
            src: p3,
            source: p3,
            rows: 2,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 2,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 2,
            cols: 1,
        },
        {
            src: p6,
            source: p6,
            rows: 2,
            cols: 1,
        },
        {
            src: p7,
            source: p7,
            rows: 2,
            cols: 1,
        },
        {
            src: p8,
            source: p8,
            rows: 2,
            cols: 1,
        },

    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                    Class 6 to Eagle Unbound Resort
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 6   DATE OF EVENT: 4 October 2024

                                </Typography>
                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    National Public School, Yeshwanthpur organised a field trip for students of Class 6 to Eagle Unbound Resort.
                                    They provided a balanced mix of educational and recreational activities, such as nature walks and adventure sports,
                                    which kept the students engaged and excited throughout the day. This event allowed students to learn about the environment,
                                    including plant and animal species, in an interactive and hands-on manner.
                                    <br />
                                    The resort’s facilities were clean and the staff ensured a safe, child-friendly atmosphere,
                                    allowing students to participate confidently in all the activities. The group exercises promoted teamwork and social bonding
                                    among the students. Overall, the trip achieved its objectives of promoting learning, teamwork and recreation,
                                    making it a memorable outing for the students. It was a highly successful and enriching student experience.
                                    <br />

                                    <br />

                                </Typography>
                            </Box>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive_2022 />
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default Class6Fieldtrip2024;